import {
	FULL_HOST
} from '@/env'

export const fullLink = (url) => {
	if (/(http|https):\/\/([\w.]+\/?)\S*/.test(url) || /data:image.*?/.test(url)) {
		return url
	} else {
		return FULL_HOST + url
	}
}

