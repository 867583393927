import { Request } from "@/uni_modules/endless-ui/utils";
import { toastApp } from "@/common/utils/toast";
import { useUser } from "@/common/stores";
import { appLocale, i18n } from "@/common/init/i18n";
import { REQUEST_SUCCESS_CODE } from "@/env";
import { empty } from "@/common/utils";
import { toast } from "../utils/toast";
let user;
const setStores = () => {
  if (!user) {
    user = useUser();
  }
};
const request = new Request({
  baseURL: import.meta.env.VITE_API_URL + "/api",
  validateStatus: (code) => code >= 200 && code <= 500,
  modifyData: (request) =>
    new Promise((resolve, reject) => {
      request
        .then((res) => {
          if (res.data) {
            if (res.data.code === REQUEST_SUCCESS_CODE) {
              resolve(res.data);
            } else {
              reject(res.data);
              if (res.data.code !== 401) {
                res.data.msg && toastApp(res.data.msg);
              }
            }
          } else {
            reject(res);
          }
        })
        .catch(resolve);
    }),
});

// 设置请求头的token等字段，只需在初始化或有改动时设置即可，无需在拦截器中每次都去判断，设置请求头，减少不需要的操作
const setRequestHeader = () => {
  let Authorization = uni.getStorageSync("token");
  request.setConfig((config) => {
    let header = {
      "content-type": "application/json",
      lang: appLocale.value,
    };
    if (Authorization) {
      header["token"] = Authorization;
    }
    config.header = header;
    return config;
  });
};

const deleteNullValue = (data) => {
  if (empty(data)) return {};
  Object.keys(data).forEach((key) => {
    if (empty(data[key])) {
      delete data[key];
    }
  });
  return data;
};

request.interceptors.request.use((config) => {
  setStores();
  /* 请求之前拦截器 */
  if (config.custom?.auth === true) {
    let token = uni.getStorageSync("token");
    // 所有需要登录的请求都不能在app初始化前调用，否则会因为pinia没有初始化而报错

    // 401会删除token 所以请求没有token时候会
    if (!token) {
      user.setLogin(false);
      user.outLogin();
      toastApp(i18n.global.t("identity.tx9"));
      uni.navigateTo({
        url: "/pages/account/login",
      });
      return Promise.reject(config);
    }
  }
  if (config.custom?.isLoading) {
    uni.showLoading({
      title: "loading...",
      mask: true,
    });
  }
  config.data = deleteNullValue(config.data);
  return config;
});
request.interceptors.response.use((response) => {
  if (response.config.custom?.isLoading) {
    uni.hideLoading();
  }
  /* 请求之后拦截器 */
  if (response.data.code === 401) {
    if (response.config.custom?.auth === true) {
      // 服务端返回的状态码不等于200，则reject()
      user.outLogin();
      setRequestHeader();
      toastApp(i18n.global.t("identity.tx9"));
      uni.navigateTo({
        url: "/pages/account/login",
      });
      return Promise.reject(401);
    } else {
      return Promise.reject(401);
    }
  }
  return Promise.resolve(response);
});

export { setRequestHeader };
export default request;
