//请求成功的状态码
export const REQUEST_SUCCESS_CODE = 1
export const THEME_PRIMARY = '#E00037'
export const FULL_HOST = 'https://idsystem.oss-ap-southeast-1.aliyuncs.com'

export const LANG_LIST = [
    {
        name:'简体中文',
        id:'zh-Hans',
    },
    {
        name:'한국어',//韩语
        id:'ko'
    },
	{
	    name:'English',//英语
	    id:'en'
	},
	// {
	//     name:'Português',//葡萄牙
	//     id:'pt'
	// },
	// {
	//     name:'Deutsch',//德语
	//     id:'de',
	// },
	// {
	//     name:'日本語',//日语
	//     id:'ja'
	// },
 //    {
 //        name:'Italiano',//意大利语
 //        id:'it'
 //    },
 //    {
 //        name:'Français',//法语
 //        id:'fr'
 //    }
    
   
]

export const NAV_LIST = [
	{
		name:'card.title',
		icon:'icon1',
		url:'/pages/card',
		id:1
	},
	{
		name:'noticeEvent.title',
		icon:'icon2',
		url:'/pages/noticeEvent',
		id:2
	},
	{
		name:'operatingChart.title',
		icon:'icon3',
		url:'/pages/operatingChart',
		id:3
	},
	{
		name:'specification.title',
		icon:'icon4',
		url:'/pages/specification',
		id:4
	},
	{
		name:'partsList.title',
		icon:'icon5',
		url:'/pages/partsList',
		id:5
	},
	{
		name:'download.title',
		icon:'icon6',
		url:'/pages/download',
		id:6
	},
	{
		name:'remark.title',
		icon:'icon7',
		url:'/pages/remark',
		id:7
	},
	{
		name:'afterSale.title',
		icon:'icon8',
		url:'/pages/afterSale',
		id:8
	}
]

// 文件类型
export const FILE_TYPES = [
	{
		name:'allType',
		id:'all'
	},
	{
		name:'pdf',
		id:'pdf'
	},
	{
		name:'image',
		id:'image'
	},
	{
		name:'video',
		id:'video'
	}
]