'use strict'
import {isURLSearchParams,forEach,isArray,isDate,isObj} from '../../utils'
function encode(val:string) {
    return encodeURIComponent(val)
        .replace(/%40/gi, '@')
        .replace(/%3A/gi, ':')
        .replace(/%24/g, '$')
        .replace(/%2C/gi, ',')
        .replace(/%20/g, '+')
        .replace(/%5B/gi, '[')
        .replace(/%5D/gi, ']')
}

/**
 * Build a URL by appending params to the end
 *
 * @param {string} url The base of the url (e.g., http://www.google.com)
 * @param {object} [params] The params to be appended
 * @returns {string} The formatted url
 */
export default function buildURL(url:string, params?:Record<string, any>) {
    /* eslint no-param-reassign:0 */
    if (!params) {
        return url
    }

    let serializedParams:string
    if (isURLSearchParams(params)) {
        serializedParams = params.toString()
    } else {
        const parts:Array<string> = []

        forEach(params, (val, key) => {
			let _key = ''
            if (val === null || typeof val === 'undefined') {
                return
            }

            if (isArray(val)) {
                _key = `${key}[]`
            } else {
                val = [val]
				_key = `${key}`
            }

            forEach(val, (v) => {
                if (isDate(v)) {
                    v = v.toISOString()
                } else if (isObj(v)) {
                    v = JSON.stringify(v)
                }
                parts.push(`${encode(_key)}=${encode(v)}`)
            })
        })

        serializedParams = parts.join('&')
    }

    if (serializedParams) {
        const hashmarkIndex = url.indexOf('#')
        if (hashmarkIndex !== -1) {
            url = url.slice(0, hashmarkIndex)
        }

        url += (url.indexOf('?') === -1 ? '?' : '&') + serializedParams
    }

    return url
}
