export type CodeInput = {
	adjustPosition?: boolean,
	maxlength?: string|number,
	dot?: boolean,
	mode?: "box"|"line",
	hairline?: boolean,
	space?: string|number,
	modelValue?: string|number,
	focus?: boolean,
	bold?: boolean,
	color?: string,
	fontSize?: string|number,
	size?: string|number,
	disabledKeyboard?: boolean,
	borderColor?: string,
	bgColor?: string,
	disabledDot?: boolean
};
export type CodeInputPropsType = Omit<CodeInput,'mode'>&{
	mode?:string
}
const codeInput: CodeInput = {
  // codeInput组件
   adjustPosition: true,
   maxlength: 6,
   dot: false,
   mode: 'box',
   hairline: false,
   space: 10,
   focus: false,
   bold: false,
   color: "#606266",
   fontSize: 18,
   size: 35,
   disabledKeyboard: false,
   borderColor: "#c9cacc",
   bgColor:"#fff",
   disabledDot: true
 
};
export default codeInput;
