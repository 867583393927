import { ref } from "vue";
import { defineStore } from "pinia";
import { getUserInfo } from "@/common/request/api";

export const useUser = defineStore("user", () => {
  const isLogin = ref(false);
  const userInfo = ref(
    uni.getStorageSync("userInfo") ? uni.getStorageSync("userInfo") : {}
  );
  const identity = ref();
  const setLogin = (type) => {
    isLogin.value = type;
  };
  const setidentity = (data) => {
    identity.value = data;
  };
  const setUserInfo = (info) => {
    userInfo.value = info;
  };
  const _getUserInfo = () =>
    new Promise((resolve, reject) => {
      getUserInfo()
        .then((res) => {
          setLogin(true);
          setUserInfo(res.data.userinfo);
          uni.setStorageSync("userInfo", res.data.userinfo);
          resolve(res);
        })
        .catch((e) => {
          reject(e);
        });
    });
  const fethcUserInfo = () => {
    return getUserInfo();
  };
  const outLogin = () => {
    // logout()
    uni.removeStorageSync("token");
    uni.removeStorageSync("userInfo");
    setLogin(false);
    setUserInfo({});
  };
  return {
    isLogin,
    userInfo,
    identity,
    setidentity,
    setLogin,
    setUserInfo,
    getUserInfo: _getUserInfo,
    outLogin,
    fethcUserInfo,
  };
});
