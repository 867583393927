// 是否为url地址
export const isURL = (s: string) => /^http[s]?:\/\/.*/.test(s);
/**
 * 判断是否为URLSearchParams，来自axios源码
 * Determine if a value is a URLSearchParams object
 *
 * @param {Object} val The value to test
 * @returns {boolean} True if value is a URLSearchParams object, otherwise false
 */
export const isURLSearchParams = (
	val: Record<string, any>
): val is URLSearchParams => {
	return (
		typeof URLSearchParams !== "undefined" && val instanceof URLSearchParams
	);
};
// 是否为图片Base64地址
export const isImgBase64 = (s: string) => /^data:image?/.test(s);

// 通过Object.prototype.toString精准判断数据类型
const verifyType = (o: unknown, type: string) =>
	Object.prototype.toString.call(o).slice(8, -1) === type;
// 判断是否为字符串
export const isString = (o: unknown): o is string => verifyType(o, "String");
// 判断是否为数字
export const isNumber = (o: unknown): o is number => verifyType(o, "Number");
// 判断是否为boolean
export const isBoolean = (o: unknown): o is boolean => verifyType(o, "Boolean");
// 判断是否为函数
export const isFunction = (o: unknown): o is Function =>
	verifyType(o, "Function");
// 判断是否为null
export const isNull = (o: unknown): o is null => verifyType(o, "Null");
// 判断是否为undefined
export const isUndefined = (o: unknown): o is undefined =>
	verifyType(o, "Undefined");
// 判断是否为对象

export const isObj = (o: unknown): o is Record<any, any> =>
	verifyType(o, "Object");

// 判断是否为json
export const isJson = (o: string): o is string => {
	if (typeof o === "string") {
		try {
			const obj = JSON.parse(o);
			return !!(typeof obj === "object" && obj);
		} catch (e) {
			return false;
		}
	}
	return false;
};
// 判断是否为数组

export const isArray = Array.isArray;
// 判断是否为时间
export const isDate = (o: unknown): o is Date => verifyType(o, "Date");
// 判断是否为正则
export const isRegExp = (o: unknown): o is RegExp => verifyType(o, "RegExp");
// 判断是否为错误对象
export const isError = (o: unknown): o is Error => verifyType(o, "Error");
// 判断是否为Symbol函数
export const isSymbol = (o: unknown): o is symbol => verifyType(o, "Symbol");
// 判断是否为Promise对象
export const isPromise = <T = any>(o: unknown): o is Promise<T> =>
	verifyType(o, "Promise");
// 判断是否为Set对象
export const isSet = <T = any>(o: unknown): o is Set<T> => verifyType(o, "Set");
// 判断是否为Map对象
export const isMap = <K = any, T = any>(o: unknown): o is Map<K, T> =>
	verifyType(o, "Map");
// 判断是否为图片
export const isImage = (value: string) => {
	let regExp = /\.(jpeg|jpg|gif|png|svg|webp|jfif|bmp|dpg)/i;
	return regExp.test(value);
};
// 判断是否为视频
export const isVideo = (value: string) => {
	let regExp = /\.(mp4|mpg|mpeg|dat|asf|avi|rm|rmvb|mov|wmv|flv|mkv)/i;
	return regExp.test(value);
};
export const isEqual = (obj1: unknown, obj2: unknown) => {
	// 两个数据有任何一个不是对象或数组,则直接判断值是否严等于
	if (!isObj(obj1) || !isObj(obj2) || !isArray(obj1) || !isArray(obj2)) {
		return obj1 === obj2
	}
	// 如果传的两个参数都是同一个对象或数组
	if (obj1 === obj2) {
		return true
	}

	// 两个都是对象或数组，而且不相等
	//比较obj1和obj2的key的个数，是否一样
	const obj1Keys = Object.keys(obj1)
	const obj2Keys = Object.keys(obj2)
	if (obj1Keys.length !== obj2Keys.length) {
		return false
	}

	// 如果key的个数相等,则递归比较
	for (let key in obj1) {
		const res = isEqual(obj1[key], obj2[key])
		if (!res) {
			return false
		}
	}

	return true
}

// 判断一个值是否为空
export const empty = (val: unknown) => {
	return (
		(!val && val !== 0) ||
		(isArray(val) && val.length === 0) ||
		(isObj(val) && !Object.keys(val).length)
	);
};

// 判断一个值是否为数字
export const testNumber = (value?: string | number) => {
	return /^[\+-]?(\d+\.?\d*|\.\d+|\d\.\d+e\+\d+)$/.test(`${value}`);
};

export const hasOwnProperty = Object.prototype.hasOwnProperty;
// 判断一个属性是否为传入的对象的自有属性，源码来自@vue/shared 详见：https://www.npmjs.com/package/@vue/shared
export const hasOwn = (val: object, key: string | symbol): key is never =>
	hasOwnProperty.call(val, key);

// 判断字符串是否仅包含字母
export const letter = (s: string) => {
	return /^[a-zA-Z]*$/.test(s);
};

// 判断字符串是否仅包含字母与数字
export const enOrNum = (s: string) => {
	const reg = /^[0-9a-zA-Z]*$/g;
	return reg.test(s);
};


/**
 * 验证一个值是否在给定的范围内[min, max]
 *
 * @param {number|string} val
 * @param {[number|string,number|string]} param 
 * @returns {boolean} 
 */
export const checkNumberRange = (value: number | string, arr: [number | string, number | string]): boolean => {
	if (empty(value)) return false
	return value >= arr[0] && value <= arr[1]
}