import { isString } from '.'

type DateOpt = Record<string, string>;
// 可自定义组合的时间戳格式化，来自uview-ui的源码
// 改进了两点，1是兼容dateTime为空的情况，2是兼容safari旧版本不支持yyyy-mm-dd格式的日期格式化，将-替换为/
export type TimeFormat = (dateTime: number | string | null, fmt?: string) => string;
export const timeFormat: TimeFormat = (dateTime = null, fmt = "yyyy/mm/dd") => {
	if (!dateTime) dateTime = new Date().getTime();
	if (isString(dateTime)) {
		dateTime = dateTime.replace('-\g','/')
	} else {
		// 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
		if (dateTime.toString().length == 10) dateTime *= 1000;
	}

	const date = new Date(dateTime);
	let ret;
	const opt: DateOpt = {
		"y+": date.getFullYear().toString(), // 年
		"m+": (date.getMonth() + 1).toString(), // 月
		"d+": date.getDate().toString(), // 日
		"h+": date.getHours().toString(), // 时
		"M+": date.getMinutes().toString(), // 分
		"s+": date.getSeconds().toString(), // 秒
	};
	let k: string;
	for (k in opt) {
		ret = new RegExp(`(${k})`).exec(fmt);
		if (ret) {
			fmt = fmt.replace(
				ret[1],
				ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
			);
		}
	}
	return fmt;
};
const numberToInt = (val: number | string) => parseInt(`${val}`);

export type TimeFrom = (dateTime: number | null, fmt: string | false) => string;
/**
 * 时间戳转为多久之前，来自uview-ui的源码
 * @param String timestamp 时间戳
 * @param String | Boolean format 如果为时间格式字符串，超出一定时间范围，返回固定的时间格式；
 * 如果为布尔值false，无论什么时间，都返回多久以前的格式
 */
export const timeFrom: TimeFrom = (timestamp = null, format = "yyyy/mm/dd") => {
	if (timestamp == null) timestamp = Number(new Date());
	timestamp = parseInt(`${timestamp}`);
	// 判断用户输入的时间戳是秒还是毫秒,一般前端js获取的时间戳是毫秒(13位),后端传过来的为秒(10位)
	if (timestamp.toString().length == 10) timestamp *= 1000;
	let timer = new Date().getTime() - timestamp;
	timer = numberToInt(timer / 1000);
	// 如果小于5分钟,则返回"刚刚",其他以此类推
	let tips = "";
	switch (true) {
		case timer < 300:
			tips = "刚刚";
			break;
		case timer >= 300 && timer < 3600:
			tips = `${numberToInt(timer / 60)}分钟前`;
			break;
		case timer >= 3600 && timer < 86400:
			tips = `${numberToInt(timer / 3600)}小时前`;
			break;
		case timer >= 86400 && timer < 2592000:
			tips = `${numberToInt(timer / 86400)}天前`;
			break;
		default:
			// 如果format为false，则无论什么时间戳，都显示xx之前
			if (format === false) {
				if (timer >= 2592000 && timer < 365 * 86400) {
					tips = `${numberToInt(timer / (86400 * 30))}个月前`;
				} else {
					tips = `${numberToInt(timer / (86400 * 365))}年前`;
				}
			} else {
				tips = timeFormat(timestamp, format);
			}
	}
	return tips;
};
// 日期的月或日补零
export const padZero = (value: string | number) => {
	return `00${value}`.slice(-2);
};

export type DaysBetweenDates = (dateA: Date, dateB: Date) => number;
/**
 * @description 计算两个日期之间的天数
 * @param dateA Date类型
 * @param dateB
 * @returns {number}
 */
export const daysBetweenDates: DaysBetweenDates = (dateA, dateB): number => {
	const timeDifference = Math.abs(dateA.getTime() - dateB.getTime());
	return Math.floor(timeDifference / (3600 * 24 * 1000));
};


/**
 * @description 获取某个日期指定时间间隔之前或之后的日期
 * @param dateA Date类型
 * @param times 间隔多少timeType，正数则往后获取，负数则往前获取
 * @param timeType 时间类型 day|week|month|year，默认day
 * @returns {Date}
 */
export const GetDateTheSpecifiedIntervalDays = (dateA: Date | string, times: number, timeType: 'day' | 'week' | 'month' | 'year' = 'day'): Date => {
	let day = 0;
	const nowDate = new Date(dateA)
	const _times = Math.abs(times)
	let [_day, _month, _year] = [nowDate.getDate(), nowDate.getMonth(), nowDate.getFullYear()]
	switch (timeType) {
		case 'day':
			day = _times
			break
		case 'week':
			day = _times * 7
			break
		case 'month':
			const month = _times % 12
			let newMonth = _month + 1
			if (month === 0) {
				// 间隔的月份数是12的倍数，则只计算年份即可
				const yearNum = _times / 12
				_year = _year + (times > 0 ? yearNum : -yearNum)
			} else {
				if (times > 0) {
					_year = _times > 12 ? _year + (_times - month) / 12 : _year
					newMonth = newMonth + month
					if (newMonth > 12) {
						_year++
						newMonth = newMonth - 12
					}
				} else {
					_year = _times > 12 ? _year - (_times - month) / 12 : _year
					newMonth = newMonth - month
					if (newMonth < 1) {
						_year--
						newMonth = 12 + newMonth
					}
				}
			}




			if (newMonth === 2 && _day === 29) {
				// 防止出现闰二月的情况
				_day = new Date(getMonthEndTime(`${_year}/02/01`)).getDate()
			}
			return new Date(`${_year}/${padZero(newMonth)}/${padZero(_day)}`)
		case 'year':
			_year = _year + times
			if (_month === 1 && _day === 29) {
				// 防止出现闰二月的情况
				_day = new Date(getMonthEndTime(`${_year}/02/01`)).getDate()
			}
			return new Date(`${_year}/${padZero(_month + 1)}/${padZero(_day)}`)
	}
	let multiple = times > 0 ? 1 : -1
	//获取开始日期时间戳
	const stime = new Date(dateA).getTime();
	return new Date(stime + 24 * 60 * 60 * 1000 * day * multiple);
};

/**
 * @description 获取某个日期前几天或后几天的日期列表
 * @param dateA Date或string类型
 * @param times 间隔多少timeType，正数则往后获取，负数则往前获取
 * @param timeType 时间类型 day|week|month|year，默认day
 * @param hasToday 返回的数据是否包含传入的日期
 * @returns {Array<Date>}
 */
export const dateBetweenDates = (dateA: Date | string, times: number, timeType: 'day' | 'week' | 'month' | 'year' = 'day', hasToday?: boolean): Array<Date> => {
	const arr: Date[] = [];
	const date = new Date(dateA)
	let day = daysBetweenDates(date, GetDateTheSpecifiedIntervalDays(dateA, times, timeType));
	console.log(day)

	let multiple = times > 0 ? 1 : -1
	//获取开始日期时间戳
	const stime = date.getTime();
	while (0 < day) {

		//增加一天时间戳后的日期
		let next_date = stime + 24 * 60 * 60 * 1000 * day * multiple;
		arr.push(new Date(next_date));

		day--
	}
	if (hasToday) {
		arr.push(date)
	}
	if (times > 0) {
		arr.reverse()
	}
	return arr;
};

/**
 * @description 获取某个日期对应月份的最后一天23时59分，不传参数则获取当前日期
 * @param date string|number
 * @returns {number}
 */
export const getMonthEndTime = (date?: string | number): number => {
	let _date = date ? new Date(date) : new Date()
	let year = _date.getFullYear()
	let month = _date.getMonth() + 1
	let nextMonthFirthDay = new Date(year, month, 1).getTime() // 下个月的第一天
	let oneDay = 1000 * 60 * 60 * 24 // 
	let endDay = new Date(nextMonthFirthDay - oneDay)
	let day = endDay.getDate() // 本月最后一天
	return new Date(`${year}/${month}/${day} 23:59:59`).getTime()
}