import {
	ref
} from 'vue'
import {
	createI18n
} from 'vue-i18n'
import de from '@/locale/de.json'
import en from '@/locale/en.json'
import fr from '@/locale/fr.json'
import it from '@/locale/it.json'
import ja from '@/locale/ja.json'
import ko from '@/locale/ko.json'
import pt from '@/locale/pt.json'
import zhHans from '@/locale/zh-Hans.json'


import {
	setRequestHeader
} from '@/common/request'
const messages = {
	de,
	en,
	fr,
	it,
	ja,
	ko,
	pt,
	'zh-Hans': zhHans,
	
}



export const i18n = createI18n({
	locale: uni.getStorageSync('locale') || 'en',
	messages
})
const init = (app) => {
	app.use(i18n)
}

export const appLocale = ref(uni.getStorageSync('locale') || 'en')


export const setLocale = (locale) => {
	uni.setStorageSync('locale', locale)
	appLocale.value = locale
	i18n.global.locale = locale
	uni.setLocale(locale)
	setRequestHeader()
}

export default init
