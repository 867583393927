
// 消息提示
const ToastConfig = {
    duration: 1000 // 弹窗显示时间
}
const toastApp = function (msg) {
    // #ifdef APP-PLUS
    plus.nativeUI.toast(msg)
    // #endif
    // #ifndef APP-PLUS
    toast(msg)
    // #endif
}

/**
 * 消息提示
 * @method $toast
 * @for UinAppUtil
 * @param {title, ok, mask, fail}  内容,icon类型,遮罩,失败回调,成功回调
 */
const toast = function (msg, iType, mask, ok, fail) {
    uni.showToast({
        title: msg,
        icon: 'none',
        mask: mask || false,
        image: iType ? `/static/img/${iType}.png` : '',
        duration: ToastConfig.duration,
        // position: icon?'center':'bottom',
        success: ok,
        fail: fail
    })
}
export { toastApp, toast }