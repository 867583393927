export type DropdownMenuItem = {
	modelValue?:string|number,
	title?:string,
	options?:Array<Record<string,any>>
	disabled?:boolean,
	customStyle?: Record<string,any>|string
};
export type DropdownMenuItemPropsType = DropdownMenuItem;
const dropdownMenuItem: DropdownMenuItem = {
	// dropdownMenu 组件
	disabled:false
};
export default dropdownMenuItem;
