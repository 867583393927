
export default {
	data () {
		return {
			_appTitle:''
		}
	},
	onShow () {
		document.title = ''
		this.$nextTick(() => {
			const pages = getCurrentPages()
			const pagePath = `/${pages[pages.length - 1]?.route ?? ''}`
			if(!pagePath)return
			switch (pagePath) {
				case '/':
				case '/pages/index':
				document.title = this.$t('app.name')
				break;
				default :
				console.log(`${pagePath.replace(/\/pages\//g,'').split('/').join('.')}.title`)
				document.title = this.$t(`${pagePath.replace(/\/pages\//g,'').split('/').join('.')}.title`)
			}
			// console.log(pagePath)
			// console.log(this.$t('app.name'))
		})
		
	}
}