import App from './App.vue'
import util from '@/common/globalProperties'
import {
	createSSRApp
} from 'vue'
import * as Pinia from 'pinia';
import endlessUi from '@/uni_modules/endless-ui'
import {
	setRequestHeader
} from '@/common/request'
import {
	THEME_PRIMARY
} from '@/env'
import {
	i18nInit
} from '@/common/init'
import titleMixin from '@/common/mixins/titleMixin'

if (
	import.meta.env.DEV) {
	uni.setStorageSync('userOrderSn', '01202303017600017230101')//开发环境订单号
}

if (!Array.prototype.at) {
	Array.prototype.at = function(index) {
		index = Math.floor(index);

		if (index === undefined) {
			index = 0;
		} else if (index < 0) {
			index = index + this.length;
		}
		return this[index];
	};
}


setRequestHeader()

export function createApp() {
	const app = createSSRApp(App)
	app.mixin(titleMixin)
	app.use(endlessUi, {
		input: {
			disabledColor: 'transparent'
		},

		form: {
			requiredPosition: 'right'
		},
		radio: {
			activeColor: THEME_PRIMARY
		},

		picker: {
			confirmColor: THEME_PRIMARY
		},

		switch: {
			activeColor: THEME_PRIMARY
		}
	});
	i18nInit(app)
	app.use(Pinia.createPinia());
	util.install(app.config)
	return {
		app,
		Pinia
	}
}