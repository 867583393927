import api from "../index";
// 用户信息
export const getUserInfo = () => api.get("user/userinfo");

// ID卡列表
export const getIDCardList = (data) =>
  api.get("device/id_image", {
    data,
    custom: {
      isLoading: true,
    },
  });
// 注意事项列表
export const getNoticeEventList = (data) =>
  api.get("device/notice_event", {
    data,
    custom: {
      isLoading: true,
    },
  });
// 营业图列表
export const getOperatingChartList = (data) =>
  api.get("device/operating_chart", {
    data,
    custom: {
      isLoading: true,
    },
  });
// 说明书列表
export const getSpecificationList = (data) =>
  api.get("device/specification", {
    data,
    custom: {
      isLoading: true,
    },
  });
// 清单列表
export const getPartsList = (data) =>
  api.get("device/parts_list", {
    data,
    custom: {
      isLoading: true,
    },
  });
// 售后申请列表
export const getAfterSaleList = () =>
  api.get("after_sale/list", {
    custom: {
      isLoading: true,
    },
  });
// 识别是否在中国境内
export const checkAddress = (data) =>
  api.post("/validate/check_address", { data });
// 添加备注
export const postAddRemark = (data) =>
  api.post("remark/create", {
    data,
    custom: {
      auth: true,
      isLoading: true,
    },
  });

// 备注历史列表
export const getRemarkList = (data) =>
  api.get("remark/list", {
    data,
    custom: {
      auth: true,
      isLoading: true,
    },
  });
// 备注回复列表
export const getRemarkReplyList = (data) =>
  api.get("remark/reply_list", {
    data,
    custom: {
      auth: true,
      isLoading: true,
    },
  });

// 获取openID
export const getUserOpenId = (code) => {
  return api.post("user/getUserOpenid", {
    data: {
      code,
    },
    custom: {
      isLoading: true,
    },
  });
};

// 获取用户是否关注公众号
export const getWechatUserInfo = (openid) => {
  return api.get("user/getWechatUserInfo", {
    data: {
      openid,
    },
    custom: {
      // isLoading: true,
    },
  });
};

export const getOrderInfo = (order_sn) => {
  return api.get("index/order_info", {
    data: {
      order_sn,
    },
    custom: {
      isLoading: true,
      auth: true,
    },
  });
};
