import {
	THEME_PRIMARY
} from '@/env'
import {
	toast,
	toastApp
} from '@/common/utils/toast'

const objToUri = (obj) => {
	return Object.keys(obj)
		.map(function(k) {
			return k + '=' + obj[k]
		})
		.join('&')
}
const jumpCheck = function (url,options = {})  {

	let goToUrl = Object.keys(options).length > 0 ? `${url}?${objToUri(options)}` : url
	return goToUrl
	
}

export default {
	init({
		globalProperties
	}) {
		/**
		 * 此方法在APP下使用原生消息提示
		 * @method $toastApp
		 * @for UinAppUtil
		 * @param {msg} 提示内容
		 */
		globalProperties.$toastApp = toastApp

		globalProperties.$toast = toast
		/**
		 * 弹窗提示框
		 * @method $showModal
		 * @for UinAppUtils
		 * @param  params
		 * 与uni.showModal参数一致
		 */
		globalProperties.$showModal = function(params) {
			if (!params.title) {
				params.title = "提示"
			}
			params.cancelColor = params.cancelColor || '#4D4D4D',
				params.confirmColor = params.confirmColor || THEME_PRIMARY
			uni.showModal(params)
		}
		/**
		 * 页面跳转
		 * @method $goTo
		 * @for UinAppUtil
		 * @param {url, hasLogin, options}  跳转链接,是否需要登录,参数
		 */
		globalProperties.$goTo = function(...args) {
			uni.navigateTo({
				url: jumpCheck.apply(this,args)
			})
		}
		/**
		 * 页面跳转-关闭当前
		 * @method $goRedirectTo
		 * @for UinAppUtil
		 * @param {url, hasLogin, options}  跳转链接,是否需要登录,参数
		 */
		globalProperties.$goRedirectTo = function(...args) {
			uni.redirectTo({
				url: jumpCheck.apply(this,args)
			})
		}
		/**
		 * 页面跳转-关闭所有页面
		 * @method $goReLaunch
		 * @for UinAppUtil
		 * @param {url, hasLogin, options}  跳转链接,是否需要登录,参数
		 */
		globalProperties.$goReLaunch = function(...args) {
			uni.reLaunch({
				url: jumpCheck.apply(this,args)
			})
		}
		globalProperties.$back = function(delta = 1) {
			uni.navigateBack({
				delta
			})
		}
	}
}
