<script setup>
	import {
		useUser
	} from '@/common/stores';
	import {
		onHide,
		onShow,
		onLaunch
	} from '@dcloudio/uni-app'
	onLaunch(() => {
		const userStores = useUser()

		userStores.getUserInfo()
	})
	onShow(() => {})
	onHide(() => {})
</script>

<style lang="scss">
	/*每个页面公共css */
	@import './common/css/index.scss';
	@import "./common/css/flex.scss";
	@import '@/uni_modules/endless-ui/index.scss';

	/*  #ifndef  APP-NVUE  */
	@import 'common/css/for.scss';

	page {
		@include set-app-css-var-value(e-color-primary, $base-color-primary);
		@include set-app-css-var-value(e-color-error, $base-color-red);
		@include set-app-css-var-value(e-color-warning, $base-color-warning);
		--e-border-radius-square: 5px;

	}

	/*  #endif  */
</style>
